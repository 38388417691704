import React from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as IconLock } from '@kesko/icons/misc/icon-lockClosed.svg';
import kcmLogo from 'images/k-citymarket20px.png';
import ksmLogo from 'images/k-supermarket20px.png';
import kmLogo from 'images/k-market20px.png';
import { toJS } from 'mobx';

interface PreviewContentProps {
  borderColor: string;
  isKCM: boolean;
}

const shouldForwardProp = (prop: string) => !['borderColor', 'isKCM'].includes(prop);

const SectionHeader = styled.div`
  h2 {
    font-size: 1.5rem;
    margin: 0;
  }
`;

const PreviewBox = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px;
  gap: 8px;
  width: 343px;
  height: auto;
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.04)) drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.12));
`;

const PreviewContent = styled.div.withConfig({
  shouldForwardProp,
})<PreviewContentProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px; /* Adjust to create inner spacing */
  width: 335px;
  height: 310px;
  background: #ffffff;
  border-radius: 12px;
  border: 4px solid ${(props) => props.borderColor};
  position: relative;
  z-index: 0;
  ${(props) =>
    props.isKCM &&
    css`
      background:
        linear-gradient(white, white) padding-box,
        linear-gradient(45deg, #fc955d, #fecbcc) border-box;
      border: 4px solid transparent;
    `}
`;

const TopContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 2px;
  width: 100%;
  height: 50%;
  flex: none;
  order: 0;
  flex-grow: 1;
`;

const LeftSide = styled.div`
  width: 105px;
  height: auti;
  margin-left: 12px;
`;

const LeftSideContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 31px 9px;
  gap: 10px;
  width: auto;
  height: auto;
  left: 0px;
  top: 0px;
  margin-right: 6px;
  border-radius: 16px 0px 0px 16px;
`;

const Benefit = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;

  position: absolute;
  width: 105px;
  height: 27.87px;
  left: 8px;
  top: 8px;
`;

const DiscountBadge = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2px 12px;
  gap: 4px;
  height: 24px;
  background: #cb4700;
  border-radius: 8px 0px;
  position: relative;
  bottom: 8px;
  right: 8px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
`;

const DiscountText = styled.p`
  /* title */
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  margin: 0;
`;

const ProductImageContainer = styled.div`
  padding-bottom: 4px;
  gap: 4px;
  width: auto;
  height: 91px;
  margin: 10px 10px 0px 0px;
`;

const ProductImage = styled.img`
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  border-radius: 8px;
`;

const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px 0px 0px 4px;
  gap: 4px;
  width: 229px;
  height: auto;
  border-radius: 0px 16px 16px 0px;
  flex: none;
  order: 1;
  flex-grow: 1;
  position: relative;
  bottom: 10px;
`;

const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 8px;
  width: auto;
  height: 100%;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
  justify-content: space-between;
`;

const Restriction = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 4px 2px 4px;
  background-color: #f0f0f0;
  border-radius: 8px;
  width: auto;
  height: auto;
`;

const RestrictionText = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  color: #3e3e3e;
`;

const H3Retailer = styled.h3`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: ${(props) => props.color};
  order: 1;
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  width: auto;
  height: auto;
  order: 3;
  margin-top: 6px;
`;

const Price = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  order: 1;
  width: 108px;
  height: 24px;
`;

const Euros = styled.p`
  width: auto;
  height: auto;
  font-style: normal;
  font-weight: 900;
  font-size: 38px;
  line-height: 44px;
  /* identical to box height, or 138% */
  display: flex;
  align-items: flex-end;
  margin: 0px;
  color: #cb4700;
  position: relative;
  right: 2px;
`;

const FractionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: auto;
  height: 35px;
`;

const Fraction = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  margin-bottom: 0;
  width: 19px;
  height: 14px;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 14px;
  /* identical to box height, or 100% */
  color: #cb4700;
  position: relative;
  right: 4px;
`;

const UnitText = styled.p`
  /* Unit value */
  margin: 0px;
  position: relative;
  right: 2px;
  width: 23px;
  height: 14px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 17px;
  /* or 142% */
  display: flex;
  align-items: center;
  color: #282828;
`;

const Rules = styled.div`
  /* Rules */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  margin-top: 1px;
  width: auto;
  height: 29px;
  order: 4;
`;

const NormalPrice = styled.p`
  /* price-reference */

  width: 108px;
  height: 34px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  /* or 142% */

  color: #565656;
`;

const BottomContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 8px;
  width: 100%;
  height: auto;
  border-radius: 0px 0px 16px 16px;
  flex: none;
  order: 1;
  flex-grow: 0;
  position: relative;

  &::before {
    content: '';
    width: 319px;
    height: 0px;
    border: 1px solid #dddddd;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const OfferDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  gap: 3px;

  width: auto;
  height: auto;

  border-radius: 8px;
`;

const Title = styled.p`
  order: 2;
  overflow-wrap: normal;
  width: 100%;
  flex-grow: 1; /* Allow it to grow */
  margin: 0; /* Ensure no margin */
`;

const PreviewFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 6px 12px;
  gap: 8px;
  width: 343px;
  height: auto;
  border-radius: 0px 0px 16px 16px;
`;

const StoreDetails = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;

  width: auto;
  height: 20px;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
`;

const StoreLogo = styled.div`
  /* chain logo */

  width: auto;
  height: 20px;
  padding-bottom: 2px;
  align-self: flex-start;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
`;

const StoreLogoImage = styled.img`
  width: auto;
  height: auto;
  top: 4px;
  left: 12px;
`;

const StoreName = styled.p`
  height: auto;
  line-height: 20px;
  width: auto;
  font-weight: 600;
  font-size: 15px;
  text-transform: uppercase;
  padding: 2px 0;
`;

const ActivationButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 10px;
  width: 100%;
  height: auto;
  order: 2;
`;

const ActivationButton = styled.div`
  /* Button */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8.5px 32px;
  gap: 10px;
  width: 247px;
  height: 22px;
  background: #cb4700;
  border-radius: 45px;
`;

const ActivationButtonText = styled.p`
  /* Label */

  width: 84px;
  height: 23px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 140% */
  display: flex;
  align-items: center;
  position: relative;
  bottom: 2px;
  color: #ffffff;
`;

const StyledIconLock = styled(IconLock)`
  color: ${(props) => props.color}; /* Default styling */

  /* SVG-specific fallback */
  & path {
    fill: ${(props) => props.color};
  }

  & svg {
    width: ${(props) => props.width || '24px'};
    height: ${(props) => props.height || '24px'};
  }
`;

const MobileOffersPreview = ({ delivery, daysUntil, storeId, userStore }) => {
  const hasOffers = delivery?.deliveryOffers?.length > 0;
  console.log(toJS(userStore.me));
  const price =
    hasOffers && delivery?.deliveryOffers[0]?.discountPercentage
      ? delivery.deliveryOffers[0].discountPercentage
      : hasOffers && delivery?.deliveryOffers[0]?.productOfferType === 'gratis'
        ? 0.0
        : hasOffers
          ? delivery?.deliveryOffers[0]?.discountPrice
          : null;

  const prices = delivery?.deliveryOffers[0]?.products.map((product) => product.price);
  const discountPrice = delivery?.deliveryOffers[0]?.discountPrice;
  const discountPercentages = prices
    ? prices.map((price) => {
        return discountPrice ? Math.floor((1 - discountPrice / price) * 100) : 0;
      })
    : [];
  const minDiscountPercentage = discountPercentages ? Math.min(...discountPercentages) : 0;
  const maxDiscountPercentage = discountPercentages ? Math.max(...discountPercentages) : 0;
  const minPrice = prices ? Math.min(...prices) : 0;
  const maxPrice = prices ? Math.max(...prices) : 0;

  const isKCM = storeId.startsWith('N');
  const isKSM = storeId.startsWith('S');
  const borderColor = isKCM ? 'transparent' : isKSM ? '#002855' : '#CB4700';
  const h3Color = isKCM ? '#CB4700' : '#002855';
  const lockColor = '#282828';
  const getStoreName = (storeName: string, storeType: string) => {
    const nameParts = storeName.replace(storeType, '').trim().split(' ');
    if (storeType === 'K-Citymarket') {
      return nameParts.length > 2 ? nameParts.slice(-2).join(' ') : nameParts.slice(-1).join(' ');
    } else {
      return nameParts.join(' ');
    }
  };

  const storeName = isKCM
    ? getStoreName(userStore.me.storeData.name, 'K-Citymarket')
    : isKSM
      ? getStoreName(userStore.me.storeData.name, 'K-Supermarket')
      : getStoreName(userStore.me.storeData.name, 'K-Market');

  return (
    <>
      {delivery.deliveryOffers.length > 0 && (
        <>
          <SectionHeader>
            <h2>Esikatselu</h2>
          </SectionHeader>
          <PreviewBox>
            <PreviewContent borderColor={borderColor} isKCM={isKCM}>
              <TopContent>
                <LeftSide>
                  <LeftSideContent>
                    <Benefit>
                      <DiscountBadge>
                        <DiscountText>
                          -{minDiscountPercentage}
                          {minDiscountPercentage !== maxDiscountPercentage && `-${maxDiscountPercentage}`} %
                        </DiscountText>
                      </DiscountBadge>
                    </Benefit>
                    <ProductImageContainer>
                      <ProductImage src={delivery.deliveryOffers[0].image} alt={delivery.deliveryOffers[0].title.fi} />
                    </ProductImageContainer>
                  </LeftSideContent>
                </LeftSide>
                <RightSide>
                  <RightContent>
                    <Restriction>
                      <StyledIconLock color={lockColor} width="16px" height="16px" />
                      <RestrictionText>Etu ei vielä aktivoitu</RestrictionText>
                    </Restriction>
                    <H3Retailer style={{ margin: '0' }} color={h3Color}>
                      Kauppiaalta sinulle
                    </H3Retailer>
                    <Title>{delivery.deliveryOffers[0].title.fi}</Title>
                    <PriceContainer>
                      {delivery.deliveryOffers[0].discountPercentage ? (
                        <Price>
                          <Euros>
                            <strong>-{price} %</strong>
                          </Euros>
                        </Price>
                      ) : (
                        <Price>
                          <Euros>
                            <strong>{Math.floor(price)}</strong>
                          </Euros>
                          <FractionContainer>
                            <Fraction style={{ margin: '0' }}>
                              <strong>{(price % 1).toFixed(2).slice(2)}</strong>
                            </Fraction>
                            <UnitText>/{delivery.deliveryOffers[0].pricingUnit}</UnitText>
                          </FractionContainer>
                        </Price>
                      )}
                    </PriceContainer>
                    <Rules>
                      <NormalPrice>
                        Norm. {minPrice}
                        {minPrice !== maxPrice && `-${maxPrice}`}/kpl
                      </NormalPrice>
                    </Rules>
                  </RightContent>
                </RightSide>
              </TopContent>
              <BottomContent>
                <PreviewFooter style={{ display: 'flex', flexDirection: 'column' }}>
                  <OfferDetails>
                    <p style={{ margin: '0' }}>
                      Etua jäljellä <strong>{delivery.deliveryOffers[0].itemLimit} kpl</strong> - Voimassa vielä{' '}
                      <strong>
                        {daysUntil(new Date(delivery.offerStartDate), new Date(delivery.offerEndDate))} päivää
                      </strong>
                    </p>
                    <p style={{ margin: '0', fontSize: '14px', fontWeight: '600', lineHeight: '20px' }}>
                      Etu vain kaupasta
                    </p>
                  </OfferDetails>
                  <StoreDetails>
                    <StoreLogo>
                      <StoreLogoImage
                        src={isKCM ? kcmLogo : storeId.startsWith('S') ? ksmLogo : kmLogo}
                        alt="K-ryhmä"
                      />
                    </StoreLogo>
                    <StoreName>{storeName}</StoreName>
                  </StoreDetails>
                  <ActivationButtonContent>
                    <ActivationButton>
                      <ActivationButtonText>Aktivoi etu</ActivationButtonText>
                    </ActivationButton>
                  </ActivationButtonContent>
                </PreviewFooter>
              </BottomContent>
            </PreviewContent>
          </PreviewBox>
        </>
      )}
    </>
  );
};

export default MobileOffersPreview;
