/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unnecessary-type-assertion, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-misused-promises */
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import _ from 'utils/lodash';
import { upload } from 'utils/api/other';
import { ReactComponent as PlusIcon } from '@kesko/icons/action/icon-plus.svg';
import { ReactComponent as RemoveIcon } from '@kesko/icons/action/icon-delete.svg';
import { ReactComponent as UploadIcon } from '@kesko/icons/action/icon-upload.svg';
import { ReactComponent as ClearIcon } from '@kesko/icons/action/icon-clear.svg';
import InputText from 'components/common/next/form/inputText';
import Spinner from 'components/common/next/spinner';
import { imageSrc, getImageFileDimensions, ImgixOpts, ensureString } from 'utils/helpers';
import Dropdown from 'components/next/components/dropdown';

import './inputImage.scss';
import { croppingOptions, croppingTranslations } from 'utils/helpers';

interface Props {
  value: { src: string; text?: string };
  onChange: ({ src, text }: { src: string; text?: string }) => any;
  onUpload?: (file: File) => any;
  label?: string;
  instructions?: string;
  constraints?: {
    minWidth?: number;
    minHeight?: number;
  };
  acceptTypes?: string;
  readOnly?: boolean;
  withText?: boolean;
  uploadDirectory?: string;
  styles?: {
    maxHeight?: number;
  };
  disableDelete?: boolean;
  imgixStyle?: ImgixOpts;
  dropdown?: {
    lang: 'fi' | 'en';
  };
}

const getSelectedOption = (src: string, lang: 'en' | 'fi') => {
  const selected =
    croppingOptions.find((option) => src && src.includes(ensureString(option.queryParams))) || croppingOptions[0];
  return {
    name: croppingTranslations[lang][selected.value],
    value: selected.queryParams,
  };
};

const getOptions = (lang: 'en' | 'fi') => {
  return croppingOptions.map((option) => ({
    name: croppingTranslations[lang][option.value],
    value: option.queryParams,
  }));
};

const InputImage = forwardRef(
  (
    {
      value,
      onChange,
      onUpload,
      label,
      instructions,
      constraints,
      acceptTypes,
      readOnly,
      withText,
      uploadDirectory,
      styles,
      disableDelete,
      dropdown,
    }: Props,
    ref,
  ) => {
    const [uploading, setUploading] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [imageFile, setImageFile] = useState<File>(); // new image file that is not yet uploaded
    useImperativeHandle(ref, () => ({
      getImageFile: () => imageFile,
    }));
    const getImgStyles = () => {
      const maxHeight = styles?.maxHeight || 400;
      const minHeight = maxHeight < 200 ? maxHeight : 200;
      return {
        minHeight: `${minHeight}px`,
        maxHeight: `${maxHeight}px`,
      };
    };

    const getContainerStyles = () => {
      const maxHeight = styles?.maxHeight || 400;
      const minHeight = maxHeight < 200 ? maxHeight : 200;
      return {
        height: `${minHeight}px`,
      };
    };

    const changeImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files as FileList;
      if (files.length === 1) {
        const file = files[0];

        if (constraints) {
          const allowedFileDimensions = await getImageFileDimensions(file, constraints.minWidth, constraints.minHeight);
          if (!allowedFileDimensions) {
            setErrorText(
              `The image dimensions are too small. Minimum width: ${constraints.minWidth}px, minimum height: ${constraints.minHeight}px`,
            );
            return false;
          }
        }

        setImageFile(file);
        if (onUpload) {
          onUpload(file);
        }
        setErrorText('');
      }
      event.target.value = ''; // reset value so same file can be selected again
    };

    const uploadImage = async () => {
      if (imageFile) {
        setUploading(true);
        setErrorText('');
        try {
          const src = await upload(imageFile, uploadDirectory);
          setImageFile(undefined);
          setUploading(false);
          if (onChange) {
            onChange({ src, text: value?.text || undefined });
          }
        } catch (err) {
          let errorMsg = '';
          switch (err.response.status) {
            case 415:
              errorMsg = 'Tiedoston lataus epäonnistui. Virheellinen tiedostotyyppi.';
              break;
            default:
              errorMsg = 'Tiedoston lataus epäonnistui.';
              break;
          }
          setUploading(false);
          setErrorText(errorMsg);
        }
      }
    };

    const removeImage = () => {
      setImageFile(undefined);
      setUploading(false);
      if (onChange) {
        onChange({ src: undefined, text: value?.text || undefined });
      }
    };

    const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const text = e.target.value;
      onChange({ src: value?.src || undefined, text });
    };

    const renderSelectPrompt = () => {
      const containerStyles = getContainerStyles();
      if (readOnly) {
        return (
          <div className="disabled-prompt" style={containerStyles}>
            <ClearIcon />
          </div>
        );
      }
      return (
        <div className="select-prompt" style={containerStyles}>
          <PlusIcon />
        </div>
      );
    };

    const renderPreview = () => {
      const imgStyles = getImgStyles();
      if (imageFile) {
        return <img src={URL.createObjectURL(imageFile)} alt="preview" className="image-preview" style={imgStyles} />;
      }
      if (!value?.src) {
        return renderSelectPrompt();
      }
      if (value.src.match('pdf')) {
        return <embed src={`${value.src}#toolbar=0&navpanes=0`} height="420" width="300" type="application/pdf" />;
      }
      if (dropdown) {
        const hasImgixStyles = value.src.includes('?');
        const src = hasImgixStyles ? value.src : `${value.src}?${croppingOptions[0].queryParams}`;
        return <img src={src} alt="preview" className="image-preview" style={imgStyles} />;
      }
      return (
        <img
          src={imageSrc(value.src, { fm: 'png', h: 400 })}
          alt="preview"
          className="image-preview"
          style={imgStyles}
        />
      );
    };

    const renderImageInput = () => {
      if (uploading) {
        const containerStyles = getContainerStyles();
        return (
          <div className="image-loader" style={containerStyles}>
            <Spinner />
          </div>
        );
      }

      const classes = ['image-upload'];
      if (readOnly) {
        classes.push('readonly');
      }

      return (
        <div className={classes.join(' ')}>
          {renderPreview()}
          {!readOnly && (
            <input
              type="file"
              name="file"
              accept={acceptTypes || 'image/*'}
              className="image-input"
              onChange={changeImage}
            />
          )}
        </div>
      );
    };

    const renderTextInput = () => {
      if (!withText) {
        return null;
      }

      return (
        <div className="image-text">
          <InputText
            textarea
            key="image-text"
            name="image-text"
            label={label ? `${label} (teksti)` : 'Kuvateksti'}
            value={value?.text || undefined}
            onChange={onTextChange}
            readOnly={readOnly}
          />
        </div>
      );
    };

    const handleDropdownChange = (dropdownValue: string | number | boolean) => {
      const baseUrl = getBaseUrl(value.src);
      const croppedUrl = `${baseUrl}?${dropdownValue}`;
      onChange({
        ...value,
        src: croppedUrl,
      });
    };

    const getBaseUrl = (url: string) => {
      if (url.includes('?')) {
        const index = url.indexOf('?');
        return index === -1 ? url : url.substring(0, index);
      }
      return url;
    };

    const renderImageActions = () => {
      if (readOnly) {
        return null;
      }

      if (imageFile || value?.src) {
        const uploadClasses = ['upload-image'];
        const removeClasses = ['remove-image'];
        if (uploading) {
          uploadClasses.push('disabled');
          removeClasses.push('disabled');
        }
        return (
          <div className="image-actions">
            {dropdown && (
              <Dropdown
                className="custom-dropdown"
                data={getOptions(dropdown.lang)}
                notSelectedText={dropdown.lang === 'fi' ? 'Valitse kuvan rajaus' : 'Choose cropping method'}
                selectedItem={getSelectedOption(value.src, dropdown.lang)}
                select={(item) => handleDropdownChange(ensureString(item))}
              />
            )}
            {imageFile && (
              <div className={uploadClasses.join(' ')} onClick={!uploading ? uploadImage : undefined}>
                <UploadIcon /> Tallenna kuva
              </div>
            )}
            {!disableDelete && (
              <div className={removeClasses.join(' ')} onClick={!uploading ? removeImage : undefined}>
                <RemoveIcon /> Poista
              </div>
            )}
          </div>
        );
      }

      return null;
    };

    return (
      <div className="form-control">
        {label && <label>{label}</label>}
        {instructions && <small>{instructions}</small>}
        <div className="input-image">
          {renderImageInput()}
          {renderImageActions()}
          {renderTextInput()}
        </div>
        <small className="error-text">{errorText}</small>
      </div>
    );
  },
);

InputImage.displayName = 'InputImage';

export default InputImage;
