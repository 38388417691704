/* eslint-disable @typescript-eslint/no-unsafe-argument, 	@typescript-eslint/no-unsafe-assignment, 	@typescript-eslint/no-unnecessary-type-assertion, @typescript-eslint/no-unsafe-member-access, 	@typescript-eslint/no-misused-promises */
import React from 'react';
import _ from 'lodash';
import * as date from 'date-fns';
import { inject, observer } from 'mobx-react';
import DeliveriesStore from 'stores/next/deliveries';
import StoreStore from 'stores/next/stores';
import ConceptsStore from 'stores/next/programs';
import ResultsStore, { AggregatedDeliveryResults } from 'stores/next-retailer/resultsStore';
import StatisticsStore from 'stores/statisticsStore';
import DeliveryTemplateStore from 'stores/next/deliveryTemplates';
import page from 'components/next/pages/page/page';
import Spinner from 'components/common/next/spinner';
import { DateField } from 'components/next/components/form/input';
import type { Route, Delivery, DeliveryTemplate, Store, DeliveryTemplateTabStatistics } from 'types/next';
import { Checkbox } from '../../../components/checkbox';
import SearchBox from 'components/next/components/sidebar/searchBox';
import SidebarWrapper from 'components/next/components/sidebar/sidebar';
import ChainSelector from 'components/next/components/chainSelector';
import { getExcludedChains, castDate, getAllowedConceptTypeForChainIds } from 'utils/helpers';
import { dateFormat, payloadDateFormat } from 'components/next/utils';
import * as dateFns from 'date-fns';
import { ChainAbbreviations } from 'constants/common';
import { ConceptType, DeliveryChannelName, DeliveryStatus, DeliveryState } from 'enums/common';

interface DeliveryTemplatesState {
  chainIds: string[];
  excludedChains: string[];
  channels: { [type: string]: boolean };
  isLoading: boolean;
  order: 'asc' | 'desc';
  query: string;
  deliverySearchResult: Delivery[];
  deliveryTemplateSearchResult: DeliveryTemplate[];
  selectedDeliveries: string[];
  selectedDeliveryTemplates: DeliveryTemplate[];
  sort?: string;
  startDateFrom: string;
  startDateTo: string;
  states: { [type: string]: boolean };
  statuses: { [type: string]: boolean };
  storeIds: string[];
  stores: { [id: string]: Store };
  types: { [type: string]: boolean };
  deliveryResults: AggregatedDeliveryResults[];
  categorizedResults: {
    recipients: number;
    opens: number;
    optOuts: number;
    clicks: number;
    totalRedeemers: number;
    pullAmount: number;
    pullCount: number;
    avgPull: number;
    storesParticipating: string[];
    householdsRedeemed: number;
  };
  archiveStatusChangeDisabled: boolean;
  currentSelectionArchiveStatus: boolean;
  archived: boolean;
  messagesSent: number;
  statistics?: DeliveryTemplateTabStatistics;
}

interface DeliveryTemplatesProps {
  deliveriesStore: DeliveriesStore;
  deliveryTemplateStore: DeliveryTemplateStore;
  conceptsStore: ConceptsStore;
  storeStore: StoreStore;
  resultsStore: ResultsStore;
  chainIds: string[];
  statisticsStore: StatisticsStore;
  getPageLink(route: Route, id: string): string;
}

type StateDateField = keyof DeliveryTemplatesState;

@inject('deliveriesStore', 'storeStore', 'resultsStore', 'conceptsStore', 'deliveryTemplateStore', 'statisticsStore')
@observer
class DeliveryTemplates extends React.Component<DeliveryTemplatesProps, DeliveryTemplatesState> {
  debouncedSearchDeliveryTemplates: (updateStores?: boolean) => void;

  constructor(props) {
    super(props);
    const { chainIds } = this.props;
    this.state = {
      query: '',
      chainIds,
      excludedChains: getExcludedChains(chainIds),
      storeIds: [],
      selectedDeliveries: [],
      selectedDeliveryTemplates: [],
      stores: {},
      sort: 'id',
      order: 'desc',
      isLoading: true,
      types: _.reduce(
        Object.keys(ConceptType),
        (types, key) => {
          const type = ConceptType[key as string];
          types[type] = true;
          return types;
        },
        {},
      ),
      channels: _.reduce(
        Object.keys(DeliveryChannelName),
        (channels, key) => {
          const channel = DeliveryChannelName[key as string];
          channels[channel] = true;
          return channels;
        },
        {},
      ),
      statuses: { [DeliveryStatus.Finished]: true, [DeliveryStatus.Ongoing]: true },
      states: { [DeliveryState.Confirmed]: true },
      startDateFrom: null,
      startDateTo: null,
      deliverySearchResult: [],
      deliveryTemplateSearchResult: [],
      categorizedResults: {
        recipients: 0,
        opens: 0,
        optOuts: 0,
        clicks: 0,
        totalRedeemers: 0,
        pullCount: 0,
        pullAmount: 0,
        avgPull: 0,
        storesParticipating: [],
        householdsRedeemed: 0,
      },
      archiveStatusChangeDisabled: false,
      currentSelectionArchiveStatus: false,
      archived: false,
      deliveryResults: [],
      messagesSent: 0,
    };
    this.debouncedSearchDeliveryTemplates = _.debounce(
      (updateStores = false) => this.searchDeliveryTemplates(updateStores),
      500,
    );
  }

  get deliveryTemplates() {
    return this.state.deliveryTemplateSearchResult;
  }

  get selectedDeliveryTemplates() {
    return this.state.selectedDeliveryTemplates;
  }

  get resultsStore() {
    return this.props.resultsStore;
  }

  get results() {
    return this.props.resultsStore.deliveryResults;
  }

  get deliveriesStore() {
    return this.props.deliveriesStore;
  }

  get participatingStores() {
    const { categorizedResults } = this.state;
    return _.uniq(categorizedResults.storesParticipating).length;
  }

  get deliveriesMadeWithCurrentTemplate() {
    const templateIds = this.deliveryTemplates.map((t) => t.id);
    const deliveryNumber = this.deliveriesStore.deliveries.filter((d) => _.includes(templateIds, d.deliveryTemplate));
    return deliveryNumber;
  }

  updateStatistics = (templateIds: string[]): void => {
    const { startDateFrom, startDateTo } = this.state;
    this.setState({
      categorizedResults: {
        recipients: 0,
        clicks: 0,
        opens: 0,
        optOuts: 0,
        totalRedeemers: 0,
        pullCount: 0,
        pullAmount: 0,
        avgPull: 0,
        storesParticipating: [],
        householdsRedeemed: 0,
      },
    });

    /* eslint-disable */
    let {
      recipients,
      opens,
      optOuts,
      clicks,
      totalRedeemers,
      pullCount,
      pullAmount,
      storesParticipating,
      avgPull,
      householdsRedeemed,
    } = this.state.categorizedResults;
    /* eslint-enable */

    // local helper function
    function addToStatistics(item: DeliveryTemplateTabStatistics) {
      recipients += item.deliveries.reduce((acc, curr) => acc + curr.recipients, 0);
      opens += item.deliveries.reduce((acc, curr) => acc + curr.opens, 0);
      optOuts += item.deliveries.reduce((acc, curr) => acc + curr.optOuts, 0);
      clicks += item.deliveries.reduce((acc, curr) => acc + curr.clicks, 0);
      totalRedeemers += item.deliveries.reduce((acc, curr) => acc + curr.redeemers, 0);
      pullCount += item.deliveries.length;
      pullAmount += item.deliveries.reduce((acc, curr) => acc + curr.pull, 0);
      storesParticipating.push(...item.storesParticipating);

      householdsRedeemed += item.deliveries.reduce((acc, curr) => acc + curr.householdPurchasedTotal, 0);
    }
    const mapByDeliveryTemplate = this.props.statisticsStore.deliveryTemplateStatisticsMapByTemplate;

    // If we have no selected concepts, we add all shown concepts data
    templateIds.forEach((templateId) => {
      if (mapByDeliveryTemplate.has(templateId)) {
        // If the map has deliveries and the start date is set, we filter the deliveries
        const template = mapByDeliveryTemplate.get(templateId);
        // If the deliveries have a start date that is after startDateFrom or before startDateTo, we add them to the statistics
        if (template && template.deliveries.length && (startDateFrom || startDateTo)) {
          const filteredDeliveries = template.deliveries.filter((delivery) => {
            const deliveryStartDate = date.startOfDay(new Date(delivery.startDate));
            return (
              (!startDateFrom ||
                date.isAfter(deliveryStartDate, new Date(startDateFrom)) ||
                date.isSameDay(deliveryStartDate, new Date(startDateFrom))) &&
              (!startDateTo ||
                date.isBefore(deliveryStartDate, new Date(startDateTo)) ||
                date.isSameDay(deliveryStartDate, new Date(startDateTo)))
            );
          });
          addToStatistics({
            ...template,
            deliveries: filteredDeliveries,
          });
        } else {
          addToStatistics(mapByDeliveryTemplate.get(templateId));
        }
      }
    });

    this.setState({
      categorizedResults: {
        recipients,
        clicks,
        opens,
        optOuts,
        totalRedeemers,
        pullCount,
        pullAmount,
        avgPull,
        storesParticipating,
        householdsRedeemed,
      },
    });
  };

  componentDidMount = async () => {
    await this.props.statisticsStore.getStatisticsDeliveryTemplate();
    await this.searchDeliveryTemplates(true);
    await this.props.storeStore.searchStores({});
  };

  searchDeliveryTemplates = async (updateStores = false) => {
    this.setState({ isLoading: true });
    const { deliveryTemplateStore, conceptsStore, storeStore } = this.props;
    const { query, chainIds, channels, order, sort, startDateFrom, startDateTo, types, archived } = this.state;

    const type = _.keys(_.pickBy(types, Boolean));
    const channel = _.keys(_.pickBy(channels, Boolean));
    /* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/unbound-method, @typescript-eslint/no-misused-promises */
    // Get all available concepts
    const conceptResult = await conceptsStore.search({
      chainId: chainIds.length
        ? [chainIds[0], ...chainIds.slice(1)]
        : [this.props.chainIds[0], ...this.props.chainIds.slice(1)],
    });
    const concept = _.map(conceptResult.result, 'id');

    const search = _.omitBy(
      {
        query,
        concept,
        chainId: chainIds,
        type,
        channel,
        order,
        sort,
        archived,
      },
      _.isNil,
    );
    try {
      const deliveryTemplates = await deliveryTemplateStore.search(search, { sort, order });
      const deliveryTemplateSearchResult = deliveryTemplates.result as DeliveryTemplate[];

      const mapByDeliveryTemplate = this.props.statisticsStore.deliveryTemplateStatisticsMapByTemplate;
      const searchStartDateFrom = startDateFrom ? castDate(startDateFrom) : null;
      const searchStartDateTo = startDateTo ? castDate(startDateTo) : null;

      // Get all delivery templates that have deliveries in mapByDeliveryTemplate
      const deliveryTemplatesWithDeliveries = _.filter(deliveryTemplateSearchResult, (deliveryTemplate) => {
        const current = mapByDeliveryTemplate.get(deliveryTemplate.id);
        // If startDateFrom or startDateTo is set, we need to check if the delivery template has deliveries that are within the date range
        if (searchStartDateFrom || searchStartDateTo) {
          return (
            current &&
            current.deliveries.some((delivery) => {
              const deliveryStartDate = date.startOfDay(castDate(delivery.startDate));
              return (
                (!startDateFrom ||
                  date.isAfter(deliveryStartDate, searchStartDateFrom) ||
                  date.isSameDay(deliveryStartDate, searchStartDateFrom)) &&
                (!startDateTo ||
                  date.isBefore(deliveryStartDate, searchStartDateTo) ||
                  date.isSameDay(deliveryStartDate, searchStartDateTo))
              );
            })
          );
        }
        return true;
      });
      if (updateStores) {
        const stores = await storeStore.searchStores({ chainIds });

        this.setState({
          deliveryTemplateSearchResult,
          selectedDeliveryTemplates: deliveryTemplatesWithDeliveries,
          stores: _.keyBy(stores, 'storeId'),
          isLoading: false,
        });
      } else {
        this.setState({
          deliveryTemplateSearchResult,
          selectedDeliveryTemplates: deliveryTemplatesWithDeliveries,
          isLoading: false,
        });
      }
      this.updateStatistics(this.state.selectedDeliveryTemplates.map((d) => d.id));
    } catch (err) {
      this.setState({ isLoading: false });
    }
  };

  handleChainChange = (chain: string) => {
    const chainIds = [...this.state.chainIds];
    if (chainIds.includes(chain)) {
      _.pullAt(chainIds, chainIds.indexOf(chain));
    } else {
      chainIds.push(chain);
    }
    this.setState({ chainIds, isLoading: true });
    this.debouncedSearchDeliveryTemplates();
  };

  handleTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { types } = this.state;
    const type = e.currentTarget.dataset.conceptType;
    types[type] = !types[type];
    this.setState({ types, isLoading: true });
    this.debouncedSearchDeliveryTemplates();
  };

  handleChannelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { channels } = this.state;
    const channel = e.currentTarget.dataset.deliveryChannel;
    channels[channel] = !channels[channel];
    this.setState({ channels, isLoading: true });
    this.debouncedSearchDeliveryTemplates();
  };

  handleDateChange = (raw_date: Date, field: StateDateField) => {
    const value = date.isDate(raw_date) ? date.format(raw_date, payloadDateFormat) : undefined;
    this.setState<never>({ [field]: value || null, isLoading: true });
    this.debouncedSearchDeliveryTemplates();
  };

  // eslint-disable-next-line
  handleQueryChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    if (name === 'query') {
      this.setState({ query: value, isLoading: true });
      this.debouncedSearchDeliveryTemplates();
    }
  };
  /* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
  renderSidebar = () => {
    const { query, chainIds, excludedChains, types, channels, startDateFrom, startDateTo, isLoading } = this.state;
    return (
      <div className="deliveries-sidebar">
        <div className="deliveries-filters">
          <div className="sidebar__title-wrapper">
            <h3 className="sidebar__title">Filters</h3>
            {isLoading && <Spinner addClassName="spinner--unset" />}
          </div>
          <SearchBox
            value={query}
            name="query"
            onChange={this.handleQueryChange}
            placeholder="Search for delivery templates"
            detail="Search for concept or delivery template title"
          />
          <label>Chain</label>
          <ChainSelector
            chainSelection={chainIds}
            excludeChains={excludedChains}
            handleChainChange={this.handleChainChange}
          />

          <div className="filter-group">
            <label>Type</label>
            {_.map(getAllowedConceptTypeForChainIds(chainIds), (type) => {
              return (
                <Checkbox
                  data-concept-type={type}
                  key={`concept-type-${type}`}
                  id={`concept-type-${type}`}
                  label={_.capitalize(type)}
                  checked={types[type]}
                  handleClick={this.handleTypeChange}
                />
              );
            })}
          </div>

          <div className="filter-group">
            <label>Start date</label>
            <DateField
              value={startDateFrom ? new Date(startDateFrom) : null}
              label="From"
              onChange={(e) => this.handleDateChange(e, 'startDateFrom')}
              clearable
              placeholder={dateFormat}
            />
            <DateField
              value={startDateTo ? new Date(startDateTo) : null}
              label="To"
              onChange={(e) => this.handleDateChange(e, 'startDateTo')}
              clearable
              placeholder={dateFormat}
            />
          </div>

          <div className="filter-group">
            <label>Channels</label>
            {_.map(Object.keys(DeliveryChannelName), (key) => {
              const channel: string = DeliveryChannelName[key as string];

              return (
                <Checkbox
                  data-delivery-channel={channel}
                  key={`delivery-channel-${channel}`}
                  id={`delivery-channel-${channel}`}
                  label={_.capitalize(channel)}
                  checked={channels[channel]}
                  handleClick={this.handleChannelChange}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  getDeliveriesWithinTimeFrame = (templateId: string) => {
    const mapByDeliveryTemplate = this.props.statisticsStore.deliveryTemplateStatisticsMapByTemplate;
    const { startDateFrom, startDateTo } = this.state;
    const template = mapByDeliveryTemplate.get(templateId);

    if (template && template.deliveries.length && (startDateFrom || startDateTo)) {
      const filteredDeliveries = template.deliveries.filter((delivery) => {
        const deliveryStartDate = date.startOfDay(new Date(delivery.startDate));
        return (
          (!startDateFrom ||
            date.isAfter(deliveryStartDate, new Date(startDateFrom)) ||
            date.isSameDay(deliveryStartDate, new Date(startDateFrom))) &&
          (!startDateTo ||
            date.isBefore(deliveryStartDate, new Date(startDateTo)) ||
            date.isSameDay(deliveryStartDate, new Date(startDateTo)))
        );
      });
      return filteredDeliveries;
    } else {
      if (template && template.deliveries.length) {
        return template.deliveries;
      }
    }
  };

  renderDeliveryRows = () => {
    const mapByDeliveryTemplate = this.props.statisticsStore.deliveryTemplateStatisticsMapByTemplate;
    if (!this.selectedDeliveryTemplates) {
      return null;
    }
    const deliveryTemplatesAndDeliveryResults = this.selectedDeliveryTemplates.map((deliveryTemplate) => {
      const deliveries = this.getDeliveriesWithinTimeFrame(deliveryTemplate.id);
      return {
        ...deliveryTemplate,
        deliveries: deliveries,
      };
    });

    const now = new Date();
    const estimateInfo = (deliveryTemplate: DeliveryTemplateTabStatistics) =>
      deliveryTemplate && dateFns.isAfter(new Date(deliveryTemplate.deadline), now) ? ' (estimate)' : '';

    return (
      <tbody id="delivery-table-body">
        {deliveryTemplatesAndDeliveryResults.map((d) => (
          <tr key={d.id} className="delivery-row">
            <td>{d.id}</td>
            <td>{_.get(d, ['title', 'fi'])}</td>
            <td>
              {mapByDeliveryTemplate.get(d.id) &&
                mapByDeliveryTemplate
                  .get(d.id)
                  .chainIds.sort()
                  .map((c, i) =>
                    ChainAbbreviations[c] ? (
                      <span className={`chain-name ${ChainAbbreviations[c]}`} key={`chain-${i}`}>
                        {ChainAbbreviations[c]}
                      </span>
                    ) : null,
                  )}
            </td>
            <td>{_.join(_.map(d.channels, 'name'), ', ')}</td>
            <td>
              {mapByDeliveryTemplate.get(d.id)?.storesParticipating?.length || 0}
              {estimateInfo(mapByDeliveryTemplate.get(d.id))}
            </td>
            <td>{mapByDeliveryTemplate.get(d.id)?.storesWithOwnOffers || 0}</td>
            <td>
              {mapByDeliveryTemplate.get(d.id)?.storesWithOwnOffers > 0
                ? `Product: ${mapByDeliveryTemplate.get(d.id)?.ownOfferTypeCounts.product}, Basket:
                ${mapByDeliveryTemplate.get(d.id)?.ownOfferTypeCounts.basket}, Webstore:
                ${mapByDeliveryTemplate.get(d.id)?.ownOfferTypeCounts.webstoreProduct}`
                : '-'}
            </td>
            <td>{d.deliveries ? d.deliveries.reduce((acc, curr) => acc + curr.recipients, 0) : 0}</td>
            <td>{d.deliveries ? d.deliveries.reduce((acc, curr) => acc + curr.opens, 0) : 0}</td>
            <td>{d.deliveries ? d.deliveries.reduce((acc, curr) => acc + curr.clicks, 0) : 0}</td>
            <td>{d.deliveries ? d.deliveries.reduce((acc, curr) => acc + curr.optOuts, 0) : 0}</td>
            <td>{d.deliveries ? d.deliveries.reduce((acc, curr) => acc + curr.redeemers, 0) : 0}</td>
            <td>{d.deliveries ? d.deliveries.reduce((acc, curr) => acc + curr.householdPurchasedTotal, 0) : 0}</td>
            <td>
              {d.deliveries
                ? (
                    d.deliveries.reduce((acc, curr) => acc + curr.pull, 0) / // Add up all the pull numbers and divide by the number of deliveries
                    d.deliveries.length
                  ).toFixed(2)
                : '0.00'}
            </td>
          </tr>
        ))}
      </tbody>
    );
  };
  /* eslint-disable react/prop-types */
  render() {
    const { isLoading, categorizedResults } = this.state;

    return (
      <SidebarWrapper renderSidebar={this.renderSidebar}>
        <div className="results-view">
          {this.props.children}
          <div className="results-container">
            <div className="result">
              <span className="result__value">{categorizedResults.storesParticipating.length || 0}</span>
              <span className="result_detail">stores participating</span>
            </div>
            <div className="result">
              <span className="result__value">{categorizedResults.recipients || 0}</span>
              <span className="result_detail">messages sent</span>
            </div>
            <div className="result">
              <span className="result__value">{categorizedResults.opens || 0}</span>
              <span className="result_detail">emails opened</span>
            </div>
            <div className="result">
              <span className="result__value">{categorizedResults.clicks || 0}</span>
              <span className="result_detail">email links clicked</span>
            </div>
            <div className="result">
              <span className="result__value">{categorizedResults.optOuts || 0}</span>
              <span className="result_detail">recipients cancelled</span>
            </div>
            <div className="result">
              <span className="result__value">{categorizedResults.totalRedeemers.toFixed() || 0}</span>
              <span className="result_detail">offers redeemed</span>
            </div>
            <div className="result">
              <span className="result__value">{categorizedResults.householdsRedeemed.toFixed() || 0}</span>
              <span className="result_detail">households redeemed</span>
            </div>
            <div className="result">
              <span className="result__value">
                {categorizedResults.pullCount > 0
                  ? (categorizedResults.pullAmount / categorizedResults.pullCount).toFixed(2)
                  : 0.0 || 0.0}
              </span>
              <span className="result_detail">average pull</span>
            </div>
          </div>
          <div className="deliveries">
            <div className="table-container">
              <table className="styled">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Delivery template</th>
                    <th>Chain</th>
                    <th>Channels</th>
                    <th>Participating stores</th>
                    <th>Stores with own offers</th>
                    <th>Own offer types</th>
                    <th>Sent messages</th>
                    <th>Opened emails</th>
                    <th>Clicked links</th>
                    <th>Cancelled</th>
                    <th>Redeemed offers</th>
                    <th>Redeemed by household</th>
                    <th>Average pull</th>
                  </tr>
                </thead>
                {this.renderDeliveryRows()}
              </table>
              {isLoading && <Spinner />}
            </div>
          </div>
        </div>
      </SidebarWrapper>
    );
  }
}
export default page(DeliveryTemplates);
