/* eslint-disable 	@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call, @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return,  @typescript-eslint/no-floating-promises, @typescript-eslint/no-misused-promises, react/no-unescaped-entities */
import { useNavigate, useParams } from 'react-router-dom';
import CloseButton from '../primitives/closeButton';
import * as _ from 'lodash';
import { getLink } from '../../routes';
import '../push/pushForm.scss';
import GitHash from '../../../../../components/common/next/gitHash';
import Button from 'components/common/next/form/button';
import Spinner from 'components/common/next/spinner';
import { ChangeEvent, useEffect, useState } from 'react';
import type { Dimension } from 'types/next';
import { getDeliveryTemplateDefaults } from 'components/next/utils';
import UserStore from 'stores/userStore';
import { inject, observer } from 'mobx-react';
import '../delivery/deliveryForm/deliveryForm.scss';
import DeliveryStore, {
  Delivery,
  DeliveryRelations,
  DeliveryWithMeta,
  getDefaultDeliveryFromTemplate,
} from 'stores/next-retailer/deliveryStore';
import React from 'react';
import ContentStore from 'stores/next-retailer/contentStore';
import InfoPopover from '../common/infoPopover';
import { ReactComponent as ChevronRight } from '@kesko/icons/nav/icon-chevron-right.svg';
import { ReactComponent as IconLock } from '@kesko/icons/misc/icon-lockClosed.svg';
import OffersBox from '../delivery/deliveryForm/sections/offersBox';
import { toJS } from 'mobx';
import DimensionsStore from 'stores/next/dimensions';
import { ConceptType, DeliveryState, DeliveryTemplateStatus } from 'enums/common';
import TargetSelectionBox from '../delivery/deliveryForm/sections/targetSelectionBox';
import TemplateStore, { DeliveryTemplateWithMeta } from 'stores/next-retailer/templateStore';
import type Api from 'types/next-api';
import DatesBox from '../delivery/deliveryForm/sections/datesBox';
import * as date from 'date-fns';
import { castDate } from 'utils/helpers';
import './mobileOffersForm.scss';
import InputText from 'components/common/next/form/inputText';
import Alerts from 'components/common/next/alert/alerts';
import { isMobile } from '../../utils/helpers';
import AlertStore from 'stores/next/alerts';
import { AxiosError } from 'axios';
import { Icon } from '@material-ui/core';
import MobileOfferPreview from './mobileOfferPreview';

interface Props {
  userStore?: UserStore;
  deliveryStore?: DeliveryStore;
  contentStore?: ContentStore;
  dimensionsStore?: DimensionsStore;
  templateStore?: TemplateStore;
  alertStore?: AlertStore;
}
const convertDimensionsToTargetGroupOpts = (
  dimensions: Dimension[],
  deliverySegmentOptions: string[] = [],
  deliverySesonkiOptions: string[] = [],
) => {
  const targetGroupOpts = { useAreas: true, dimensions: [] };
  const allowedFromSegmentti = _.uniq(['potentiaaliset', 'parhaat', 'tarkeat', 'muut', ...deliverySegmentOptions]);
  const allowedFromSesonki = _.uniq(['mökkiläiset', ...deliverySesonkiOptions]);
  for (const d of dimensions) {
    // Do not add "Keskittämisaste" to mobile offers (name: 'sow')
    if (d.name === 'sow') {
      continue;
    }
    const targetGroupOptsDimension = {
      name: d.name,
      options: [],
      default: [],
      locked: [],
    };
    // Filter out all but allowed 'segmentti' and 'sesonki' options. Include the ones the delivery already has selected.
    let filteredOptions = d.options;
    filteredOptions =
      d.name === 'segmentti'
        ? filteredOptions.filter((option) => allowedFromSegmentti.includes(option.name))
        : filteredOptions;
    filteredOptions =
      d.name === 'sesonki'
        ? filteredOptions.filter((option) => allowedFromSesonki.includes(option.name))
        : filteredOptions;
    targetGroupOptsDimension.options = filteredOptions.map((option) => option.name);
    // Set the defaults for mobile offers
    if (d.name === 'reach') {
      targetGroupOptsDimension.default = ['coreArea'];
    }
    if (d.name === 'segmentti') {
      targetGroupOptsDimension.default = ['parhaat', 'tarkeat'];
    }
    targetGroupOpts.dimensions.push(targetGroupOptsDimension);
  }
  return targetGroupOpts;
};
const getTemplate = (
  dimensions: Dimension[],
  deliverySegmentOptions: string[] = [],
  deliverySesonkiOptions: string[] = [],
) => {
  const targetGroupOpts = convertDimensionsToTargetGroupOpts(
    dimensions,
    deliverySegmentOptions,
    deliverySesonkiOptions,
  );
  const template: DeliveryTemplateWithMeta = getDeliveryTemplateDefaults(
    undefined,
    undefined,
    ConceptType.Season,
  ) as DeliveryTemplateWithMeta;
  template.targetGroupOpts = targetGroupOpts;
  template.contentTemplates = [{ channel: 'mobile', contentSlots: [] }];
  template.channels = [
    {
      name: 'mobile',
      locked: false,
      default: true,
    },
  ];
  const payloadDateFormat = 'yyyy-MM-dd';
  // Also hardcode the template's dates
  template.firstStartDate = date.format(castDate(new Date()), payloadDateFormat);
  return template;
};
const MobileOffersForm = ({
  userStore,
  deliveryStore,
  contentStore,
  dimensionsStore,
  templateStore,
  alertStore,
}: Props) => {
  const navigate = useNavigate();
  const { mobileOffersId: mobileDeliveryIdParam } = useParams();
  const [isWaiting, setIsWaiting] = useState(false);
  const template: DeliveryTemplateWithMeta = getTemplate(dimensionsStore.dimensions);
  useEffect(() => {
    const init = async () => {
      const delivery = await setDeliveryOnPageLoad();
      console.log(toJS(delivery), delivery.deliveryFields);
      // If the delivery already has unallowed dimensions set, let them be (so that the users can see them in UI)
      let modifiedTemplate = template;
      const deliveryDimensionSegmentOptions =
        delivery.targetGroup.dimensions.find((d) => d.name === 'segmentti')?.selection || [];
      const deliveryDimensionSesonkiOptions =
        delivery.targetGroup.dimensions.find((d) => d.name === 'sesonki')?.selection || [];
      if (!_.isEmpty(deliveryDimensionSegmentOptions) || !_.isEmpty(deliveryDimensionSesonkiOptions)) {
        modifiedTemplate = getTemplate(
          dimensionsStore.dimensions,
          deliveryDimensionSegmentOptions,
          deliveryDimensionSesonkiOptions,
        );
      }
      templateStore.setCurrent(modifiedTemplate);
      templateStore.updateCollection([modifiedTemplate]);
      const selectionCounts = await refreshSelectionCounts(delivery);
      deliveryStore.setCurrent({ ...delivery, maxSelectionCounts: selectionCounts });
    };
    init();
  }, []);
  const setDeliveryOnPageLoad = async () => {
    const currentDelivery = getDelivery();
    if (isNew() && !currentDelivery) {
      // Init
      return await initializeDelivery();
    }
    if (isNew() && currentDelivery) {
      // On /mobile-offers/edit/new but returning from /mobile-offers/edit/new/offer/new for example
      return currentDelivery;
    }
    if (!isNew() && currentDelivery && currentDelivery.id !== mobileDeliveryIdParam) {
      // User has manually set the url to point to /mobile-offers/edit/[deliveryId]
      return await fetchDeliveryFromApi(mobileDeliveryIdParam);
    }
    if (!isNew() && currentDelivery) {
      // On /mobile-offers/edit/[deliveryId] but returning from /mobile-offers/edit/[deliveryId]/offer/new for example
      return currentDelivery;
    }
    if (!isNew()) {
      return await fetchDeliveryFromApi(mobileDeliveryIdParam);
    }
    return await initializeDelivery();
  };
  const fetchDeliveryFromApi = async (id: string) => {
    const delivery = await deliveryStore.getDelivery(id, false);
    delivery.deliveryTemplate = '';
    return delivery;
  };
  const initializeDelivery = async () => {
    const apiTemplate = {
      ...template,
    } as Api.Components.Schemas.DeliveryTemplate & Api.Components.Schemas.DeliveryTemplateRelations;
    apiTemplate.contentBlocks = [];
    apiTemplate.contentFields = [];
    apiTemplate.offerOptions = [];
    apiTemplate.defaultOfferOptions = [];
    apiTemplate.status = DeliveryTemplateStatus.Open;
    const delivery = await getDefaultDeliveryFromTemplate(apiTemplate, ConceptType.Season, userStore.me);
    delivery.targetGroup.channels = { email: 0, print: 0, mobile: -1 };
    return delivery;
  };
  const getDeliveryName = (delivery: DeliveryWithMeta) => {
    return delivery?.description ? delivery.description : `Aktivoitavat mobiiliedut ${formatDate(new Date())}`;
  };
  const refreshSelectionCounts = async (delivery: DeliveryWithMeta) => {
    return await deliveryStore.getMaxSelectionCountsForDelivery(delivery);
  };
  const getDelivery = () => {
    // use current delivery
    return deliveryStore.current as Delivery & DeliveryRelations & DeliveryWithMeta;
  };
  const isNew = () => {
    return _.isNil(mobileDeliveryIdParam) || mobileDeliveryIdParam === 'new';
  };
  const formatDate = (date: Date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  };
  const setDeliveryField = (field: string, value: any) => {
    const d = toJS(getDelivery());
    _.set(d, field, value);
    deliveryStore.setCurrent(d);
  };
  const descriptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    const delivery = toJS(getDelivery());
    const eventValue = e.target.value;
    const description = eventValue === '' ? null : eventValue;
    delivery.description = description;
    deliveryStore.setCurrent(delivery);
  };
  const handleCancel = () => {
    const confirmed = window.confirm('Oletko varma, että haluat poistua tallentamatta muutoksia?');
    if (confirmed) {
      templateStore.unsetCurrent();
      deliveryStore.unsetCurrent();
      navigate(getLink('mobileOffers'));
    }
  };
  const handleDelete = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const confirmed = window.confirm('Oletko varma, että haluat poistaa luonnoksen? Tätä ei voida perua.');
    const delivery = getDelivery();
    if (confirmed) {
      setIsWaiting(true);
      try {
        await deliveryStore.deleteDelivery(getDelivery().id);
        deliveryStore.unsetCurrent();
        navigate(getLink('mobileOffers'));
        alertStore.success({
          message: <span>Luonnos "{delivery.description}" poistettu onnistuneesti.</span>,
        });
      } catch (error) {
        alertStore.error({
          message: 'Virhe poistaessa luonnosta.',
          ttl: 0,
        });
      }
      window.scrollTo({ top: 0 });
      setIsWaiting(false);
    }
  };
  const handleSubmit = (state: DeliveryState) => {
    return async (e: React.SyntheticEvent) => {
      e.preventDefault();
      const delivery = toJS(getDelivery());
      delivery.description = getDeliveryName(delivery);
      delivery.state = state;

      alertStore.clear();
      setIsWaiting(true);
      try {
        const saved = await deliveryStore.saveDelivery(delivery as Delivery & DeliveryRelations, false, true);
        saved.deliveryTemplate = '';
        deliveryStore.unsetCurrent();
        await deliveryStore.getDelivery(saved.id, false);
        deliveryStore.setCurrent(saved);
        if (saved && saved.hasFailedInAC) {
          refreshSelectionCounts(saved);
        }
        if (saved.hasFailedInAC) {
          alertStore.error({
            message: (
              <span>
                Mahdollinen virhe lähettämisessä. Yritä tallentaa lähetys uudelleen. Jos virhe toistuu, ota yhteyttä{' '}
                <a href="mailto:tuki.kmarkkinointi@kesko.fi">tuki.kmarkkinointi@kesko.fi</a>
              </span>
            ),
            ttl: 0,
          });
        } else if (state === DeliveryState.Draft) {
          alertStore.success({
            message: (
              <span>
                Lähetys "{saved.description}" tallennettu tilaan "luonnos". Jos haluat poistaa luonnoksen kokonaan,
                klikkaa muokkausnäkymästä vielä "Poista luonnos".
              </span>
            ),
          });
        } else if (state === DeliveryState.Confirmed) {
          alertStore.success({
            message: (
              <span>
                <strong key="thank">Kiitos! </strong>Lähetys "{saved.description}" ajastettu lähtemään{' '}
                {date.format(castDate(castDate(saved.startDate)), 'd.M.yyyy')}
              </span>
            ),
          });
        }
        setIsWaiting(false);
        if (!saved.hasFailedInAC) {
          // Everything ok, navigate back
          navigate(getLink('mobileOffers'));
        }
      } catch (error) {
        let errorMessage = (
          <span>
            Virhe lähettämisessä. Ota yhteyttä{' '}
            <a href="mailto:tuki.kmarkkinointi@kesko.fi">tuki.kmarkkinointi@kesko.fi</a>
          </span>
        );
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          //  handle errors
          const message: string = _.get(axiosError, ['response', 'data', 'message'], '');
          if (message.includes('Not enough delivery offers')) {
            errorMessage = (
              <span>
                Lisää vähintään {template.offerOpts.min} etu{template.offerOpts.min > 1 ? 'a' : ''}
              </span>
            );
          } else if (message.includes('Not enough required recommendations')) {
            errorMessage = <span>Lisää vähintään {template.offerOpts.requiredRecommendations} suositusetu(a).</span>;
          } else if (message.includes('Too many delivery offers')) {
            errorMessage = <span>Lähetyksellä on liian monta etua!</span>;
          } else if (message.includes(`Can't start more than 4 deliveries within 7 days`)) {
            errorMessage = <span>Voit aloittaa enintään 4 omaa lähetystä 7 päivän sisällä toisistaan</span>;
          } else {
            // unknown error
            errorMessage = (
              <span>
                Virhe: "{message}". Ota yhteyttä{' '}
                <a href="mailto:tuki.kmarkkinointi@kesko.fi">tuki.kmarkkinointi@kesko.fi</a>
              </span>
            );
          }
        }
        alertStore.error({
          message: errorMessage,
          ttl: 0,
        });
        window.scrollTo({ top: 0 });
        setIsWaiting(false);
      }
    };
  };

  const setDraftAndDelete = async (e: React.SyntheticEvent) => {
    setIsWaiting(true);
    e.persist();
    const delivery = toJS(getDelivery());
    delivery.state = DeliveryState.Draft;
    // although saveDelivery might fail partially, it should be still ok to delete it normally
    const saved = await deliveryStore.saveDelivery(delivery, false, true);
    saved.deliveryTemplate = '';
    deliveryStore.setCurrent(saved);
    setIsWaiting(false);
  };
  const isValidDeliveryOfferNumber = () => {
    const offersEnabled = (template.offerOptions && template.offerOptions.length > 0) || template.offerOpts.allowOwn;
    if (offersEnabled) {
      const { min } = template.offerOpts;
      if (!min) {
        return true;
      }
      return getDelivery().deliveryOffers.length >= min;
    }
    return true;
  };
  const infoButton = (opts: { slug: string; link?: string }) => {
    return (
      <InfoPopover>
        <div
          className="help-content"
          dangerouslySetInnerHTML={{
            __html: contentStore.getContentBySlug(opts.slug, { html: true }),
          }}
        />
        {opts.link && (
          <footer>
            <a href={opts.link} target="_blank" rel="noopener noreferrer">
              Avaa ohjeet uudessa ikkunassa
              <ChevronRight />
            </a>
          </footer>
        )}
      </InfoPopover>
    );
  };

  const daysUntil = (startdate: Date, endDate: Date) => {
    const diff = endDate.getTime() - startdate.getTime();
    return Math.ceil(diff / (1000 * 60 * 60 * 24) + 1);
  };

  const renderFooter = () => {
    const delivery = getDelivery();
    const zeroWarningDiv = (msg: string | undefined = undefined) => (
      <div className="warning">Huomio: Lähetyksellä on 0{msg && ' ' + msg} vastaanottajaa</div>
    );
    const validationError =
      deliveryStore.numberOfValidationErrors === 0 ? null : (
        <div className="warning">Tarkista, että olet täydentänyt kaikki lähetyksen TODO-merkinnät</div>
      );
    const zeroDeliveryTotal =
      delivery.maxSelectionCounts && delivery.maxSelectionCounts.total === 0 && zeroWarningDiv();
    const zeroDeliveryWarning = validationError || zeroDeliveryTotal || null;

    return (
      <div className="footer-buttons">
        <GitHash />
        <Button color="bordered" onClick={handleCancel} disabled={isWaiting}>
          Takaisin
        </Button>
        {!isNew() && delivery.state === DeliveryState.Draft && (
          <Button color="bordered" onClick={handleDelete} disabled={isWaiting}>
            Poista luonnos
          </Button>
        )}
        {delivery.state === DeliveryState.Draft && (
          <Button color="bordered" onClick={(e) => handleSubmit(DeliveryState.Draft)(e)} disabled={isWaiting}>
            Tallenna ja jatka myöhemmin
          </Button>
        )}
        {delivery.state !== DeliveryState.Draft && (
          <Button color="bordered" onClick={setDraftAndDelete} disabled={isWaiting}>
            Peru lähetys
          </Button>
        )}
        <Button
          color="default"
          onClick={(e) => handleSubmit(DeliveryState.Confirmed)(e)}
          disabled={isWaiting || !!validationError || !isValidDeliveryOfferNumber()}
        >
          {delivery.state === DeliveryState.Draft ? 'Lähetä' : 'Tallenna'}
        </Button>
        {zeroDeliveryWarning}
      </div>
    );
  };
  const delivery = getDelivery();
  const deliveryName = getDeliveryName(delivery);
  if (!delivery) {
    return <Spinner />;
  }
  return (
    <div className="delivery-form main-container full-page">
      <div className="header">
        <div className="full-page-content">
          <div className="text-center">
            <h1 className="form-title">{deliveryName}</h1>
            <CloseButton onClick={() => deliveryStore.unsetCurrent()} fallback={getLink('mobileOffers')} />
          </div>
        </div>
      </div>
      <div className="main-content">
        <div className="full-page-content">
          <div className="section-header">
            <h2>Edun nimi</h2>
            {infoButton({ slug: 'info-mobile-offers-delivery-name' })}
          </div>
          <div className="box">
            <InputText
              name="mobileOffersName"
              label="Kirjoita halutessasi tähän lyhyesti mobiilitarjous lähetyksen nimi. Tähän syötetty nimi näkyy vain K Markkinoinnin käyttöliittymässä, se ei näy asiakkaalle."
              value={delivery.description || ''}
              placeholder={deliveryName}
              onChange={descriptionChange}
            />
          </div>
          <div className="section-header">
            <h2>
              {/* Tuote-edut */}
              Tuote-etu
              {/* <span className="min-offer-count"> (vähintään 1, enintään 3)</span> */}
            </h2>
            {infoButton({ slug: 'info-mobile-offers-edut', link: getLink('help', 'info-edut') })}
          </div>
          <OffersBox
            deliveryOffers={toJS(delivery?.deliveryOffers || [])}
            delivery={delivery}
            template={template}
            addingDisabled={delivery.deliveryOffers?.length >= 1}
          />
          <div className="section-header">
            <h2>Kohderyhmä</h2>
            {infoButton({ slug: 'info-mobile-offers-kohderyhma', link: getLink('help', 'info-kohderyhma') })}
          </div>
          {delivery && (
            <TargetSelectionBox
              delivery={delivery}
              template={template}
              disabled={false}
              conceptType={ConceptType.Season}
            />
          )}
          <div className="section-header">
            <h2>Ajankohta</h2>
            {infoButton({ slug: 'info-mobile-offers-ajankohta', link: getLink('help', 'info-ajankohta') })}
          </div>
          <DatesBox
            delivery={delivery}
            showDates={true}
            template={template}
            setDeliveryField={setDeliveryField}
            conceptType={ConceptType.Season}
            disabled={false}
            mobileOffers={true}
          />
          <MobileOfferPreview
            delivery={delivery}
            daysUntil={daysUntil}
            storeId={delivery.storeId}
            userStore={userStore}
          />
          {delivery.maxSelectionCounts && (
            <div className="targeted-users">
              <b>
                <span className="targeted-users__value">Kohderyhmän koko:</span>Yhteensä
                <span className="targeted-users__value">{delivery.maxSelectionCounts.channels.mobile}</span>
                vastaanottajaa.
              </b>
            </div>
          )}
          <Alerts />
        </div>
      </div>
      {!isMobile() && <div className="footer with-space">{renderFooter()}</div>}
    </div>
  );
};
export default inject(
  'userStore',
  'deliveryStore',
  'contentStore',
  'dimensionsStore',
  'templateStore',
  'alertStore',
)(observer(MobileOffersForm));
