/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access */
import React from 'react';
import _ from 'utils/lodash';
import InputText from 'components/common/next/form/inputText';
import { Checkbox } from 'components/next/components/checkbox';

import './inputProduct.scss';
import InputNumber from './inputNumber';
import BttProductSelect from '../offerEditor/bttProductSelect';

interface Props {
  value: {
    title: string;
    description: string;
    url: string;
    normalPriceVat0: number | undefined;
    normalPriceVat24: number | undefined;
    salePriceVat24: number | undefined;
    loyaltyPrice: number | undefined;
    omnibus: number | undefined;
    textBlock: string;
    pricingUnit: string;
    ean: string;
    productNumber: string;
    isOnLoyaltySale: boolean;
    showTextBlock: boolean;
  };
  onChange: ({
    title,
    description,
    url,
    normalPriceVat0,
    normalPriceVat24,
    salePriceVat24,
    loyaltyPrice,
    omnibus,
    textBlock,
    pricingUnit,
    ean,
    productNumber,
    src,
    isOnLoyaltySale,
    showTextBlock,
  }: {
    title: string;
    description: string;
    url: string;
    normalPriceVat0: number | undefined;
    normalPriceVat24: number | undefined;
    salePriceVat24: number | undefined;
    loyaltyPrice: number | undefined;
    omnibus: number | undefined;
    textBlock: string;
    pricingUnit: string;
    ean: string;
    productNumber: string;
    src?: string;
    isOnLoyaltySale: boolean;
    showTextBlock: boolean;
  }) => void;
  label?: string;
  readOnly?: boolean;
  name: string;
}

interface State {}

class InputProduct extends React.Component<Props, State> {
  get productProps() {
    return _.get(this.props, ['value']);
  }

  get title() {
    return _.get(this.props, ['value', 'title']);
  }

  get description() {
    return _.get(this.props, ['value', 'description']);
  }

  get url() {
    return _.get(this.props, ['value', 'url']);
  }

  get normalPriceVat0() {
    return _.get(this.props, ['value', 'normalPriceVat0']);
  }

  get normalPriceVat24() {
    return _.get(this.props, ['value', 'normalPriceVat24']);
  }

  get salePriceVat24() {
    return _.get(this.props, ['value', 'salePriceVat24']);
  }

  get loyaltyPrice() {
    return _.get(this.props, ['value', 'loyaltyPrice']);
  }

  get omnibus() {
    return _.get(this.props, ['value', 'omnibus']);
  }

  get textBlock() {
    return _.get(this.props, ['value', 'textBlock']);
  }

  get pricingUnit() {
    return _.get(this.props, ['value', 'pricingUnit']);
  }

  get ean() {
    return _.get(this.props, ['value', 'ean']);
  }

  get productNumber() {
    return _.get(this.props, ['value', 'productNumber']);
  }

  get isOnLoyaltySale() {
    return _.get(this.props, ['value', 'isOnLoyaltySale']);
  }

  get showTextBlock() {
    return _.get(this.props, ['value', 'showTextBlock']);
  }
  public onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { onChange } = this.props;
    const value = e.target.value;
    onChange({
      ...this.productProps,
      [e.target.name]: value,
    });
  };

  // update all values when product is selected
  public onProductSelect = (product) => {
    const { onChange } = this.props;
    const {
      title,
      description,
      normalPriceVat0,
      normalPriceVat24,
      salePriceVat24,
      loyaltyPrice,
      omnibus,
      textBlock,
      pricingUnit,
      ean,
      productNumber,
      url,
      src,
      isOnLoyaltySale,
      showTextBlock,
    } = product;
    onChange({
      title,
      description,
      normalPriceVat0,
      normalPriceVat24,
      salePriceVat24,
      loyaltyPrice,
      omnibus,
      textBlock,
      pricingUnit,
      ean,
      productNumber,
      url,
      src,
      isOnLoyaltySale,
      showTextBlock,
    });
  };

  public render() {
    const { label, readOnly } = this.props;
    return (
      <div className="form-control">
        {label && <label className='find-product-label'>{label}</label>}
        <label className='find-product-label'>Etsi tuotetta</label>
        <BttProductSelect
          closeSearchOnSelect={true}
          onSelect={(product) => {
            this.onProductSelect({
              ...product,
              src: product.image,
              normalPriceVat0: undefined,
              normalPriceVat24: product.basePrice,
              salePriceVat24: product.isOnSale ? product.price : undefined,
              productNumber: product.productId,
              textBlock: this.textBlock,
              showTextBlock: this.showTextBlock,
              omnibus: null,
            });
          }}
        />
        <div className="product-input">
          <InputText
            name={`title`}
            label="Tuotteen nimi"
            placeholder="Tuotteen nimi"
            value={this.title}
            onChange={this.onValueChange}
            readOnly={readOnly}
          />

          <InputText
            name={`description`}
            label="Tuotteen kuvaus"
            placeholder="Tuotteen kuvaus"
            value={this.description}
            onChange={this.onValueChange}
            readOnly={readOnly}
            textarea
          />

          <InputText
            name={`url`}
            label="Tuotteen linkki"
            placeholder="Tuotteen linkki"
            value={this.url}
            onChange={this.onValueChange}
            readOnly={readOnly}
          />

          <InputNumber
            name={`normalPriceVat24`}
            label="Tuotteen normaali hinta alv 25,5%"
            value={this.normalPriceVat24}
            onChange={(val) => {
              this.onValueChange({
                target: {
                  name: 'normalPriceVat24',
                  value: val,
                },
              } as any);
            }}
            allowEmptyFormatting
            decimalScale={2}
            fixedDecimalScale
            min={0}
            suffix={`€`}
            readOnly={readOnly}
          />

          <InputNumber
            name={`normalPriceVat0`}
            label="Tuotteen normaali hinta alv 0% (B2B-lähetyksiin)"
            value={this.normalPriceVat0}
            onChange={(val) => {
              this.onValueChange({
                target: {
                  name: 'normalPriceVat0',
                  value: val,
                },
              } as any);
            }}
            allowEmptyFormatting
            decimalScale={2}
            min={0}
            suffix={`€`}
            readOnly={readOnly}
          />

          <InputNumber
            name={`salePriceVat24`}
            label="Tuotteen alennettu hinta alv 25,5%"
            value={this.salePriceVat24}
            onChange={(val) => {
              this.onValueChange({
                target: {
                  name: 'salePriceVat24',
                  value: val,
                },
              } as any);
            }}
            allowEmptyFormatting
            decimalScale={2}
            min={0}
            suffix={`€`}
            readOnly={readOnly}
          />

          <InputText
            name={`pricingUnit`}
            label="Tuotteen hinnoitteluyksikkö"
            placeholder="Tuotteen hinnoitteluyksikkö"
            value={this.pricingUnit}
            onChange={this.onValueChange}
            readOnly={readOnly}
          />

          <Checkbox
            label="Plussa-alennus"
            id={`${this.props.name}-isOnLoyaltySale`}
            checked={this.isOnLoyaltySale}
            handleClick={(e) => {
              console.log(e);
              this.onValueChange({
                target: {
                  name: 'isOnLoyaltySale',
                  value: e.target.checked,
                },
              } as any);
            }}
            readOnly={readOnly}
          />

          <InputNumber
            name={`loyaltyPrice`}
            label="Plussa-alennuksen hinta ALV 25,5%"
            value={this.loyaltyPrice}
            onChange={(val) => {
              this.onValueChange({
                target: {
                  name: 'loyaltyPrice',
                  value: val,
                },
              } as any);
            }}
            allowEmptyFormatting
            decimalScale={2}
            min={0}
            suffix={`€`}
            readOnly={readOnly || !this.isOnLoyaltySale}
          />

          <InputNumber
            name={`omnibus`}
            label="30 pv. alin hinta"
            value={this.omnibus}
            onChange={(val) => {
              this.onValueChange({
                target: {
                  name: 'omnibus',
                  value: val,
                },
              } as any);
            }}
            allowEmptyFormatting
            decimalScale={2}
            min={0}
            suffix={`€`}
          />

          <InputText
            name={`productNumber`}
            label="Tuotenumero"
            placeholder="Tuotenumero"
            value={this.productNumber}
            onChange={this.onValueChange}
            readOnly={readOnly}
          />

          <InputText
            name={`ean`}
            label="EAN"
            placeholder="EAN"
            value={this.ean}
            onChange={this.onValueChange}
            readOnly={readOnly}
          />

          <InputText
            name={`textBlock`}
            label="Kuvan tekstilaatikko"
            placeholder="Lisätieto"
            value={this.textBlock}
            onChange={this.onValueChange}
            readOnly={readOnly}
          />

          <Checkbox
            label="Näytä kuvan tekstilaatikko"
            id={`${this.props.name}-showTextBlock`}
            checked={this.showTextBlock}
            handleClick={(e) => {
              console.log(e);
              this.onValueChange({
                target: {
                  name: 'showTextBlock',
                  value: e.target.checked,
                },
              } as any);
            }}
            readOnly={readOnly}
          />
        </div>
      </div>
    );
  }
}

export default InputProduct;
